import React from 'react'

function ProcessPaymentButton({paymentError, ...buttonProps} : { paymentError: string }) : JSX.Element {
  return (
    <div className="max-w-xs w-full mx-auto mt-6 mb-36">
      <button className="btn-warning"{...buttonProps} >{`Submit Payment`}</button>
      {
        paymentError !== `` &&
        <p className="text-canyon font-light text-lg" id="payment_error">{paymentError}</p>
      }
    </div>
  )
}

export default ProcessPaymentButton