export type RecurringDetails = {
  intervalLength?: number,
  totalOccurrences?: number,
  startDate?: string | Date
}

export type AdditionalDetails = {
  invoiceNumber?: string,
  [property: string] : any
} & RecurringDetails

export function buildChargeBody( amount : string, additionalDetails?: AdditionalDetails ) {

  const { intervalLength, startDate, totalOccurrences } = additionalDetails as AdditionalDetails


  return {
    amount,
    // Begin Recurring Charge Details
    ...( intervalLength && { // If there is an intervalLength in additionalDetails, this is a recurring charge and we want all the other required that come along with that
      intervalLength,
      intervalUnit: `months`, // For all use-cases we use months, in the future, this hard-code could be removed
      startDate,
      totalOccurrences
    }),
    // Begin Misc Body Details
    ...additionalDetails
  }
}