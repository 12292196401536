import React from 'react'
import { TextInput, Validators, Formatters, SelectInput } from 'components/inputs/components'
import { usaShippingRegions } from 'components/inputs/constants'
import AddressInfoEditType from '../types/AddressInfoEditType'

function AddressInfoEdit({ addressData, refs, handleChange } : AddressInfoEditType ) : JSX.Element {
  return (
    <div className="mt-5">
      <TextInput
        label="First Name:"
        name="first_name"
        errorMessageClassName="text-error px-3 max-w-sm"
        labelClassName="block text-left font-light ml-5"
        value={addressData?.first_name}
        onChange={handleChange}
        validator={{
          function: Validators.name,
          failureMessage: `Enter a valid first name. Alphabet characters, spaces, dashes and apostrophes are allowed. Must be more than 1 letter`
        }}
        reference={refs.firstNameRef}
        inputClassName="input"
        onBlur={undefined}
        formatter={undefined}
        id={undefined}
      />
      <TextInput
        label="Last Name:"
        name="last_name"
        errorMessageClassName="text-error px-3 max-w-sm"
        labelClassName="block text-left font-light ml-5"
        value={addressData?.last_name}
        onChange={handleChange}
        validator={{
          function: Validators.name,
          failureMessage: `Enter a valid last name. Alphabet characters, spaces, dashes and apostrophes are allowed. Must be more than 1 letter`
        }}
        reference={refs.lastNameRef}
        onBlur={undefined}
        formatter={undefined}
        id={undefined}
      />
      <TextInput
        label="Street:"
        errorMessageClassName="text-error px-3 max-w-sm"
        labelClassName="block text-left font-light ml-5"
        name="street"
        value={addressData.street}
        onChange={handleChange}
        validator={{
          function: Validators.street,
          failureMessage: `Please input a valid street`
        }}
        reference={refs.streetRef}
        onBlur={undefined}
        formatter={undefined}
        id={undefined}
      />
      <TextInput
        label="City:"
        errorMessageClassName="text-error px-3 max-w-sm"
        labelClassName="block text-left font-light ml-5"
        name="city"
        value={addressData.city}
        onChange={handleChange}
        validator={{
          function: Validators.city,
          failureMessage: `Please input a valid city`
        }}
        reference={refs.cityRef}
        onBlur={undefined}
        formatter={undefined}
        id={undefined}
      />
      <SelectInput
        label="State:"
        name="state"
        errorMessageClassName="text-error px-3 max-w-sm"
        labelClassName="block text-left font-light ml-5"
        className="input md:max-w-xl"
        value={addressData?.state ?? undefined}
        onChange={handleChange}
      >
        <option value="" disabled hidden>{`Select a State`}</option>
        {usaShippingRegions.map( region => {
          return <option key={region.id} value={region.code}>{region.name}</option>
        })}
      </SelectInput>
      <TextInput
        label="Zip Code:"
        errorMessageClassName="text-error px-3 max-w-sm"
        labelClassName="block text-left font-light ml-5"
        name="zip"
        value={addressData.zip}
        onChange={handleChange}
        validator={{
          function: Validators.zipCode,
          failureMessage: `Please input a valid zip code`
        }}
        reference={refs.zipRef}
        formatter={{
          function: Formatters.zipCode
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } as any}
        onBlur={undefined}
        id={undefined}
      />
    </div>
  )
}

export default AddressInfoEdit