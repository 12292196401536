import React from 'react'
import { doctorStepImg } from 'assets/images'
import PropTypes from 'prop-types'

function Item({detail}) {

  return (
    <div className="flex items-center">
      <div className="mb-5">
        {
          detail?.image_source &&
          <div>
            <img
              src={detail?.image_source ?? doctorStepImg}
              alt={`product_img`}
              className="border border-solid border-lightgray object-contain p-2 flex-1"
              width={65}
              height={65}
            />
          </div>
        }
        <div className="text-left flex-1 px-2 md:px-4">
          <p className="md:text-base">{`${detail?.item_description || ``}.`}</p>
          {
            detail?.ins_payment !== undefined && detail?.patient_owes !== undefined &&
              <>
                <p className="text-sm md:text-base font-light">{`Insurance Pays: $${detail?.ins_payment.toFixed( 2 )}`}</p>
              </>
          }
        </div>
      </div>
    </div>
  )
}

Item.propTypes = {
  detail: PropTypes.shape({
    image_source: PropTypes.string,
    dme_id: PropTypes.number,
    item_description: PropTypes.string,
    quantity: PropTypes.number,
    ins_payment: PropTypes.number,
    rental_flag: PropTypes.bool,
    patient_owes: PropTypes.number,
    include: PropTypes.bool
  }),
  editMode: PropTypes.bool,
  loading: PropTypes.bool,
  handleCheck: PropTypes.func
}

export default Item