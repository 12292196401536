import React from 'react'
import PropTypes from 'prop-types'

const PaymentErrorDiv = ({authnetError, upfrontPaymentError, recurringPaymentError}) => {
  return (
    <div className="w-full mx-auto text-center mt-24 bg-white px-5">
      <div className="max-w-xl m-auto">
        {authnetError && <p className="text-canyon">{authnetError}</p>}
        {upfrontPaymentError && <p className="text-canyon">{upfrontPaymentError}</p>}
        {recurringPaymentError && <p className="text-canyon">{recurringPaymentError}</p>}
      </div>
      <div className="mt-5 lg:mt-10 min-w-fit w-1/4 m-auto">
        <button className="btn-primary" onClick={() => { history.go( 0 ) }}>{`Refresh`}</button>
      </div>
    </div>
  )
}

PaymentErrorDiv.propTypes = {
  upfrontPaymentError: PropTypes.string,
  recurringPaymentError:PropTypes.string,
  authnetError: PropTypes.string
}

export default PaymentErrorDiv