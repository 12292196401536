import { type IPaymentMethod, type CardPaymentMethod, type BankPaymentMethod, PaymentProfile, PaymentProfileResponse} from './types'
import { uuid4 } from '@sentry/utils'

export function identifyPaymentMethodType( payMethod : IPaymentMethod ) {
  if ( payMethod?.paymentMethodType ) return payMethod.paymentMethodType
  const cardPayment = payMethod as CardPaymentMethod
  const bankPayment = payMethod as BankPaymentMethod
  if ( cardPayment?.cardNumber ) return `card`
  if ( bankPayment?.accountNumber ) return `ach`

  return null
}

export function serializeCardPaymentMethod( payMethod : CardPaymentMethod ) {
  const { cardNumber, ccv, expMonth, expYear, cardholderFirstName, cardholderLastName, ...billTo } = payMethod

  return {
    cardNumber: cardNumber.split( `-` ).join( `` ),
    expirationDate: `20${expYear}-${expMonth}`,
    cardCode: ccv,
    firstName: cardholderFirstName,
    lastName: cardholderLastName,
    address: billTo.address,
    city: billTo.city,
    state: billTo.state,
    zip: billTo.zip
  }
}

export function serializeBankPaymentMethod( payMethod : BankPaymentMethod ) {
  const { accountNumber, routingNumber, nameOnAccount } = payMethod
  const { firstName, lastName } = extractFirstAndLastFromFullName( nameOnAccount )

  return {
    routingNumber,
    accountNumber,
    nameOnAccount,
    firstName,
    lastName
  }
}

export function putOrPostUrlPaymentMethod( payMethod: IPaymentMethod | null ) : string {
  const postUrl = `${process.env.REACT_APP_AUTHNET_PAYMENTS_V2_URL}/payment-profile`
  const putUrl = `${process.env.REACT_APP_AUTHNET_PAYMENTS_V2_URL}/payment-profile/${payMethod?.paymentProfileId}`
  if ( !payMethod ) return postUrl
  const { paymentProfileId } = payMethod
  if ( paymentProfileId?.length ) return putUrl

  return postUrl
}

export function serializePaymentProfileResponse( paymentProfileResp : PaymentProfileResponse ) : IPaymentMethod[] {
  return paymentProfileResp?.profile?.paymentProfiles?.map( ( paymentProfile: PaymentProfile ) => {
    const paymentMethodType = paymentProfile?.payment?.creditCard?.cardNumber?.length ? `card` : `ach`
    const _payMethod = paymentMethodType === `card` ? {
      cardNumber: paymentProfile?.payment?.creditCard?.cardNumber,
      expMonth: ``,
      expYear: ``,
      ccv: ``,
      cardholderFirstName: paymentProfile?.billTo?.firstName,
      cardholderLastName: paymentProfile?.billTo?.lastName,
      address: paymentProfile?.billTo?.address,
      city: paymentProfile?.billTo?.city,
      state: paymentProfile?.billTo?.state,
      zip: paymentProfile?.billTo?.zip
    } : {
      routingNumber: paymentProfile?.payment?.bankAccount?.routingNumber,
      accountNumber: paymentProfile?.payment?.bankAccount?.accountNumber,
      nameOnAccount: paymentProfile?.payment?.bankAccount?.nameOnAccount
    }

    return {
      paymentMethodId: uuid4(),
      paymentProfileId: paymentProfile.customerPaymentProfileId,
      paymentMethodType,
      ..._payMethod,
      isEditing: false
    } as IPaymentMethod
  }) ?? []
}

export function extractFirstAndLastFromFullName( fullName : string ) {
  const nameParts = fullName.split( ` ` )
  if ( nameParts.length === 1 ) return {
    firstName: fullName,
    lastName: ``
  }
  const firstName = nameParts[0]
  const lastName = nameParts[nameParts.length - 1]

  return {
    firstName,
    lastName
  }
}