import React from 'react'
import { icoCaretLeft } from 'assets/images'

type BackButtonProps = {
  handleClick(): void;
  tailwindPositioning: string;
}

function BackButton({handleClick, tailwindPositioning}: BackButtonProps ) : JSX.Element {
  return (
    <div className={`absolute rounded-full ${tailwindPositioning}`}>
      <button
        className="rounded-full hover:opacity-60 border border-gray2 px-4 py-1 bg-white"
        onClick={handleClick}
      >
        <img
          src={icoCaretLeft}
          alt="back_button"
        />
      </button>
    </div>
  )
}

export default BackButton