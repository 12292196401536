import * as React from 'react'
import { Link } from 'react-router-dom'
import { afDiabetesLogo } from 'assets/images'

const Logo = ( props : {withoutLink : boolean}) : JSX.Element => {
  const LogoImage = () => {
    return (
      <img
        className="object-contain"
        src={afDiabetesLogo}
        alt={`Aeroflow Diabetes Logo`}
        width="400"
        height="37"
      /> )
  }

  if ( props.withoutLink ) {
    return <LogoImage />
  }

  return (
    <Link to={`/`}>
      <LogoImage />
    </Link>
  )
}

export default Logo
