import { useEffect, useState } from 'react'
import { thankYouWhatsNext, SUMMARY_PAGE_PATH } from '../constants'
import { icoCheckAob } from 'assets/images'
import { AobLoader } from '../components'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { finalizeInvoice } from '../fetchPromises'
import { reportToSentry } from 'utils/reportToSentry'
import getHash from 'utils/getHash'

function ThankYou({ invoice, aobIsCompleted, paymentCompleted, payLater, notInterested }) {

  const [ loading, setLoading ] = useState( false )

  const navigate = useNavigate()

  useEffect( () => {
    if ( ( !aobIsCompleted || ( invoice?.total_patient_owes !== 0 && !paymentCompleted && !payLater ) ) && !notInterested ) {
      return navigate( `${SUMMARY_PAGE_PATH}${window.location.search}`, {
        replace: true
      })
    }

    let mounted = true

    const hash = getHash()
    const controller = new AbortController()

    if ( !notInterested && hash ) {
      setLoading( true )
      finalizeInvoice( hash, controller )
        .then( () => { if ( mounted ) setLoading( false ); localStorage.removeItem( `invoice-control` ) })
        .catch( ( error ) => {
          if ( !controller.signal.aborted ) setLoading( false )
          reportToSentry( new Error( `CGM AOB: Error finalizing patient invoice`, {
            cause: error
          }), {
            hash
          })
        })
    }

    return () => { controller.abort() }
  }, [] )

  if ( loading ) return <AobLoader message="Please hold tight while we finalize your invoice..." />

  return (
    <div className="bg-white flex flex-col gap-7 my-28 px-10 items-center">
      <div className="flex flex-col items-center gap-2 mt-5">
        <p className="text-2xl md:text-3xl font-bold text-center">{notInterested ? `Thank You for Your Submission!` : `Order Placed, Thank You!`}</p>
        {
          notInterested ?
            <p className="md:text-lg text-center font-light max-w-xl">{`If you change your mind all you need to do is refresh this page. We hope to see you again soon!`}</p>
            :
            <>
              {
                invoice?.rx_required ?
                  <>
                    <p className="md:text-lg text-center font-light max-w-3xl">{`Check your email for all updates regarding your order.`}</p>
                    <div className="flex flex-col items-center gap-2">
                      <p className="text-2xl md:text-3xl font-bold text-center mt-6">{`What's Next:`}</p>
                      <p className="text-xl md:text-2xl font-bold text-center">{`Aeroflow Obtains a Prescription`}</p>
                      <p className="md:text-lg text-center font-light max-w-lg">{thankYouWhatsNext}</p>
                    </div>
                  </>
                  :
                  <p className="md:text-lg text-center font-light max-w-3xl">{`Check your email for tracking information and updates regarding your order.`}</p>
              }
            </>
        }
      </div>
      <img
        src={icoCheckAob}
        alt="aob_thank_you"
        className="object-contain my-5"
      />
    </div>
  )
}

ThankYou.propTypes = {
  invoice: PropTypes.shape({
    total_patient_owes: PropTypes.number,
    rx_required: PropTypes.bool
  }),
  aobIsCompleted: PropTypes.bool,
  payLater: PropTypes.bool,
  paymentCompleted: PropTypes.bool,
  notInterested: PropTypes.bool
}

export default ThankYou