import { reportToSentry } from 'utils/reportToSentry'
import getHash from 'utils/getHash'


export type ChargeProfileIdBody = {
    amount: number;
    startDate?: string; // This is required for subscriptions
    intervalLength?: string; // This is required for subscriptions
    intervalUnit?: string; // This is required for subscriptions
    totalOccurrences?: number; // This is required for subscriptions
    invoiceNumber?: string;
    invoiceNumbers?: string[];
    orderDescription?: string;
}

export const chargePaymentProfileId = async ( paymentProfileId : string, paymentBody : ChargeProfileIdBody ) => {

  const isSubscription = Boolean( paymentBody?.totalOccurrences )
  const requestOperation = isSubscription ? `recurring-charge` : `charge`

  const response = await fetch( `${process.env.REACT_APP_AUTHNET_PAYMENTS_V2_URL}/payment-profile/${paymentProfileId}/${requestOperation}`, {
    method: `POST`,
    headers: {
      Authorization: `PatientHash ${getHash()}`
    },
    body: JSON.stringify({
      ...paymentBody,
      source: window.location.origin
    })
  })
    .catch( error => {
      reportToSentry( new Error( `Failed to make payment`, {
        cause: error
      }), {
        details: paymentBody
      })
    })

  const paymentData = await response?.json()

  const authnet_message = paymentData?.messages?.message[0]?.text ?? ``

  if ( authnet_message !== `Successful.` ) {
    console.error( authnet_message )
    reportToSentry( new Error( `Failed to make payment` ), {
      authnet_message,
      authnet_response: JSON.stringify( paymentData ?? `Authnet Response is undefined` )
    })
  }

  return authnet_message
}
