import React from 'react'
import Tooltip from 'components/tooltip'
import NotInterestedTooltip from '../../tooltips/NotInterestedTooltip'
import PropTypes from 'prop-types'

function Footer({setNotInterested}) {

  const openEmail = ( e ) => {
    e.preventDefault()

    window.open( `mailto: aeroflowdiabetes@aeroflowinc.com`, `_blank` )
  }

  return (
    <>
      <p className="text-lg text-center mb-10 max-w-lg mx-auto">
        {`If you have questions or would like to adjust your supply order, please email us at: `}
        <a className="text-teal3 font-normal cursor-pointer" onClick={openEmail}>{`aeroflowdiabetes@aeroflowinc.com`}</a>
      </p>
      <div className="relative pb-24">
        <Tooltip
          tooltipContent={<NotInterestedTooltip setNotInterested={setNotInterested} />}
          positioning="left-0 right-0 -top-12"
        >
          <div className="w-1/2 mx-auto mb-28">
            <p className="underline cursor-pointer text-center decoration-teal3 text-teal3 text-lg max-w-lg">
              {`Not interested in receiving supplies through insurance at this time? Click here.`}
            </p>
          </div>
        </Tooltip>
      </div>
    </>
  )
}

Footer.propTypes = {
  aobContent: PropTypes.string,
  setNotInterested: PropTypes.func
}

export default Footer