import PropTypes from 'prop-types'
import TotalCost from './TotalCost'

function PaymentSummary({invoice}) {

  const capitalizeFirst = ( stringInput ) => {
    return stringInput ? `${stringInput.charAt( 0 )}${stringInput.slice( 1 ).toLowerCase()}` : null
  }

  return (
    <>
      <div className="flex flex-col items-center justify-center gap-4 mb-2 mt-10">
        <p className="text-3xl tracking-wide font-bold w-10/12 md:w-8/12 text-center mt-16">{`${capitalizeFirst( invoice?.first_name )}, Here is your Price Estimate`}</p>
      </div>
      <TotalCost invoice={invoice} />
      <div>
        <p className="w-9/12 text-center font-light mx-auto mb-5">{`The displayed amounts represent a ${invoice.cgm_supply_months} month supply of sensors`}</p>
      </div>
    </>
  )
}

PaymentSummary.propTypes = {
  invoice: PropTypes.object,
  customerData: PropTypes.object
}

export default PaymentSummary