
export type DemographicsInput = {
  patientId?: number | string,
  firstName?: string,
  lastName?: string,
  dob?: string,
  phone?: string,
  emailAddress?: string,
  magentoCustomerID?: number
}

export default async function postDemographics( authorizationHeader : string, input : DemographicsInput ):Promise<boolean> {
  const reqInit = {
    headers: {
      'Content-Type': `response/json`,
      'Authorization': authorizationHeader
    },
    body: JSON.stringify( input ),
    method: `POST`
  }

  const awsResponse = await fetch( `${process.env.REACT_APP_LAMBDA_PATIENTS_URL}/update-demographics`, reqInit )

  return awsResponse?.ok ?? false
}