import React from 'react'
import PropTypes from 'prop-types'
import { icoInfoAob } from 'assets/images'
import Tooltip from 'components/tooltip'
import { PricingEstimatePercentageBar as PercentageBar } from 'components/percentageBar'
import { pricingEstimateTooltips } from 'modules/aobPayments/constants'
import { EstimateTooltipTemplate } from '../tooltips'

function Deductible({deductible, deductibleRemaining}) {

  if ( deductible === null || deductibleRemaining === null ) return null

  return (
    <div className="flex flex-col items-center justify-center w-full mb-5">
      <div className="flex justify-center" >
        <p className="text-base text-center font-light">{`Your Deductible ($${deductible})`}</p>
        <Tooltip
          tooltipContent={<EstimateTooltipTemplate {...pricingEstimateTooltips.deductible} />}
          center
        >
          <img
            src={icoInfoAob}
            alt="ico-info"
            className="px-2 hover:opacity-80 cursor-pointer object-contain"
            name="deductible"
            width={37}
          />
        </Tooltip>
      </div>
      <div className="flex flex-col w-full lg:w-10/12 px-2 my-2">
        <PercentageBar
          percentFilled={deductible > 0 ? Math.round( ( ( deductible - deductibleRemaining ) / deductible ) * 100 ) : 100}
          values={{
            paid: deductible - deductibleRemaining,
            toGo: deductibleRemaining
          }}
        />
      </div>
      <>
        {
          deductibleRemaining === 0 &&
          <p className="text-base text-center font-light text-gray1">{`Your deductible has been met`}</p>
        }
      </>
    </div>
  )
}

Deductible.propTypes = {
  deductible: PropTypes.number,
  deductibleRemaining: PropTypes.number
}

export default Deductible