import React, { useState} from 'react'
import { BankPaymentMethod, CardPaymentMethod, IPaymentMethod, PaymentProfileResponse } from 'components/payments/types.d'
import { ViewPayMethod } from './View'
import { EditCardMethod, EditBankMethod } from './Edit'
import { PaymentMethodProps } from './props.d'
import { usePayMethodsStore } from 'state'
import { useFetch } from 'hooks'
import { putOrPostUrlPaymentMethod, serializeBankPaymentMethod, serializeCardPaymentMethod } from './utils'

const PaymentMethod = ({payMethodId, blockId} : PaymentMethodProps ) => {

  const [ payMethod, setPayMethod ] = usePayMethodsStore( ( state ) => { return [ state.getPayMethod( payMethodId ), state.setPayMethod ] })
  const { removePayMethod, selectedPaymentProfiles, toggleSelectedPayMethod } = usePayMethodsStore()
  const isSelected = selectedPaymentProfiles[ blockId ] === payMethodId
  const toggleSelect = () => { return toggleSelectedPayMethod( blockId, payMethodId ) }

  const {lazyFetch : savePayMethodToAuthnet, isLoading : isSaveLoading, error: saveError } = useFetch<PaymentProfileResponse>( putOrPostUrlPaymentMethod( payMethod ), {
    method: payMethod?.paymentProfileId?.length ? `PUT` : `POST`
  })

  const { lazyFetch : deletePayMethodFromAuthnet, isLoading : isDeleteLoading, error: deleteError } = useFetch<PaymentProfileResponse>( `${process.env.REACT_APP_AUTHNET_PAYMENTS_V2_URL}/payment-profile/${payMethod?.paymentProfileId}`, {
    method: `DELETE`
  })

  const [ fetchError, setFetchError ] = useState<string>( `` )

  const paymentMethodType = payMethod?.paymentMethodType ?? null

  async function savePaymentMethod() {
    setFetchError( `` )
    const serializedPayMethod = payMethod?.paymentMethodType === `card` ? serializeCardPaymentMethod( payMethod as CardPaymentMethod ) : serializeBankPaymentMethod( payMethod as BankPaymentMethod )
    const data = await savePayMethodToAuthnet( serializedPayMethod ) // We might need to serialize this
    if ( data?.customerPaymentProfileId && data?.messages?.resultCode?.toLowerCase() === `ok` ) {
      toggleSelect() // Select new payment methods
      setPayMethod({
        ...payMethod,
        paymentProfileId: data.customerPaymentProfileId, // append the customerProfileId to the payMethod
        isEditing: false
      } as IPaymentMethod )
    } else return setFetchError( data?.messages?.message?.[0]?.text ?? `We are unable to process your payment method at this time` )
  }

  async function deletePaymentMethod() {
    setFetchError( `` )
    const response = await deletePayMethodFromAuthnet()
    if ( response?.messages?.resultCode?.toLowerCase() === `ok` ) return removePayMethod( payMethodId )
    else return setFetchError( response?.messages?.message?.[0]?.text ?? `We are unable to process your payment method at this time` )
  }

  /* --- Edit Current Payment Method --- */
  if ( payMethod?.isEditing ) {
    if ( paymentMethodType === `card` ) {
      return (
        <EditCardMethod
          payMethod={payMethod as CardPaymentMethod} setPayMethod={setPayMethod}
          onSave={savePaymentMethod}
          onCancel={() => removePayMethod( payMethodId )}
          displayLoading={isSaveLoading}
          displayError={fetchError}
        />
      )
    }

    return (
      <EditBankMethod
        payMethod={payMethod as BankPaymentMethod} setPayMethod={setPayMethod}
        onSave={savePaymentMethod}
        onCancel={() => removePayMethod( payMethodId )}
        displayLoading={isSaveLoading}
        displayError={fetchError || ( saveError?.toString() ?? `` )}
      />
    )
  }

  /* --- View Current Payment Method --- */
  return (
    <ViewPayMethod
      payMethod={payMethod as IPaymentMethod}
      toggleSelect={toggleSelect}
      isSelected={isSelected}
      handleDelete={deletePaymentMethod}
      displayLoading={isDeleteLoading}
      displayError={fetchError || ( deleteError?.toString() ?? `` )}
    />
  )
}

export default PaymentMethod