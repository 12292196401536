import React from 'react'

function RentalTooltip() {
  return (
    <div>
      <h3 className="py-3 text-center">{`Your Monthly Rental Supplies Payment`}</h3>
      <hr></hr>
      <p className="p-sm my-4">{`Your insurance company requires that Aeroflow bills your supplies as a monthly rental. At the end of your insurance company’s rental period, your supplies will then be completely owned by you and a rental payment will no longer be required.`}</p>
      <p className="p-sm my-4">{`The first month's cost of this rental is already included in your Upfront Payment. The remaining monthly rental supplies payments will begin in 30 days and is an estimate based on your health insurance coverage, deductible, and coinsurance. If a credit or follow-up statement is required, we will notify you of the difference.`}</p>
      <p className="p-sm my-4"><strong>{` If your deductible resets during your rental period, your insurance coverage terminates, or your machine usage is non-compliant as defined by your health insurance, the monthly payment amount as defined by your insurance company is subject to change.`}</strong></p>
    </div>
  )
}

export default RentalTooltip