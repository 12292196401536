import React from 'react'
import PropTypes from 'prop-types'
import { icoInfoAob } from 'assets/images'
import Tooltip from 'components/tooltip'
import { PricingEstimatePercentageBar as PercentageBar } from 'components/percentageBar'
import { pricingEstimateTooltips } from 'modules/aobPayments/constants'
import { EstimateTooltipTemplate } from '../tooltips'

function CoInsurance({coIns}) {

  if ( coIns === null ) return null

  const coInsRemaining = Math.round( 100 - coIns )

  return (
    <div className="flex flex-col items-center justify-center mb-5">
      <div className="flex justify-center" >

        <p className="text-base text-center font-light">{`Co-Insurance`}</p>
        <Tooltip
          tooltipContent={<EstimateTooltipTemplate {...pricingEstimateTooltips.coIns} />}
          center
        >
          <img
            src={icoInfoAob}
            alt="ico-info"
            className="px-2 hover:opacity-80 cursor-pointer object-contain"
            name="coIns"
            width={37}
          />
        </Tooltip>
      </div>
      <div className="flex flex-col w-full lg:w-10/12 px-2 my-2">
        <PercentageBar percentFilled={coInsRemaining} isCoInsurance />
      </div>
      <p className="text-base text-center font-light text-gray1">{`Your insurance covers ${coInsRemaining}%, you cover ${Math.round( coIns )}%`}</p>
    </div>
  )
}

CoInsurance.propTypes = {
  coIns: PropTypes.number
}

export default CoInsurance