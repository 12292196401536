import { uuid4 } from "@sentry/utils"
import { usePayMethodsStore } from "state"
import { BankPaymentMethod, CardPaymentMethod } from "../types"

export const makeEmptyCardMethod = () : CardPaymentMethod => {
  return {
    cardholderFirstName: ``,
    cardholderLastName: ``,
    cardNumber: ``,
    expMonth: ``,
    expYear: ``,
    ccv: ``,
    state: ``,
    city: ``,
    address: ``,
    zip: ``,
    paymentMethodType: `card`,
    paymentMethodId: uuid4(),
    paymentProfileId: null,
    isEditing: true
  }
}

export const makeEmptyBankMethod = () : BankPaymentMethod => {
  return {
    routingNumber: ``,
    nameOnAccount: ``,
    accountNumber: ``,
    accountType: `checking`,
    paymentMethodType: `ach`,
    paymentMethodId: uuid4(),
    paymentProfileId: null,
    isEditing: true
  }
}

export default function AddNewPayMethod({expanded, setExpanded} : {expanded: boolean, setExpanded: ( _expanded: boolean ) => void}) {

  const { addPayMethod, payMethods } = usePayMethodsStore()

  if ( !expanded && payMethods?.length !== 0 ) return (
    <div className="">
      <button className="payment-btn" onClick={() => setExpanded( true )}>{`Add New Payment Method`}</button>
    </div>
  )

  return (
    <div className="max-w-2xl mx-auto">
      <div className="flex flex-col items-center w-full max-w-md mx-auto my-5 gap-5">
        <p className="text-lg md:text-xl font-bold text-center">{`Add New Payment Method`}</p>
        <p className="md:text-lg font-bold text-center">{`Choose One`}</p>
        <button
          className="payment-btn mb-10"
          onClick={() => {
            addPayMethod( makeEmptyCardMethod() )
            setExpanded( false )
          }}
        >
          <p className="py-2 md:text-lg">{`Pay by credit or debit card`}</p>
        </button>
        <p>{`OR`}</p>
        <button
          className="payment-btn mt-7"
          onClick={() => {
            addPayMethod( makeEmptyBankMethod() )
            setExpanded( false )
          }}
        >
          <p className="py-2 md:text-lg">{`Pay by bank account`}</p>
        </button>
      </div>
    </div>
  )
}