import React, { useState } from 'react'
import { SummaryFooter } from './components'
import PropTypes, { number, string, bool, func } from 'prop-types'
import PricingEstimateBlockWrapper from '../PricingEstimateBlockWrapper'
import { Deductible, OutOfPocket, CoInsurance, InsurerNames } from '../insuranceItems'
import { icoMinus, icoPlus } from 'assets/images'
import AnimatedHeightDiv from 'components/animatedHeightDiv/AnimatedHeightDiv'

function HealthInsuranceInfoBlock({
  invoice,
  aobContent,
  setNotInterested
}) {

  const [ displayHealthInsuranceInfo, setdisplayHealthInsuranceInfo ] = useState( false )

  return (
    <>
      <PricingEstimateBlockWrapper>
        <div className="flex">
          <p className="text-xl tracking-wide font-bold text-center mt-4 mb-5" onClick={() => { return setdisplayHealthInsuranceInfo( !displayHealthInsuranceInfo ) }}>{`Health Insurance Information`}</p>
          <img
            src={displayHealthInsuranceInfo ? icoMinus : icoPlus}
            alt={displayHealthInsuranceInfo ? `ico-x` : `ico-caret-down`}
            className="object-contain ml-2 cursor-pointer hover:opacity-80"
            width={18}
            onClick={() => { return setdisplayHealthInsuranceInfo( !displayHealthInsuranceInfo ) }}
          />
        </div>
        {displayHealthInsuranceInfo &&
        <AnimatedHeightDiv
          display={displayHealthInsuranceInfo}
          heightDependencies={[ displayHealthInsuranceInfo ]}
        >
          <div>
            <InsurerNames invoice={invoice} />
            <Deductible deductible={invoice?.deductible} deductibleRemaining={invoice?.deductible_remaining} />
            <OutOfPocket oop={invoice?.oop} oopRemaining={invoice?.oop_remaining} />
            <CoInsurance coIns={invoice?.coinsurance_percentage} />

          </div>

        </AnimatedHeightDiv>

        }


      </PricingEstimateBlockWrapper>
      <PricingEstimateBlockWrapper>
        <SummaryFooter aobContent={aobContent} setNotInterested={setNotInterested} />
      </PricingEstimateBlockWrapper>
    </>
  )
}

HealthInsuranceInfoBlock.propTypes = {
  invoice: PropTypes.shape({
    oop: number,
    oop_remaining: number,
    deductible: number,
    deductible_remaining: number,
    coinsurance_percentage: number,
    emergency_contact_info_required_flag: bool,
    total_patient_owes: number,
    pay_later_eligible_flag: bool,
    scheduling_required_flag: bool,
    details: PropTypes.arrayOf( PropTypes.shape({
      hpcp_code: string,
      item_description: string,
      quantity: number,
      ins_payment: number,
      patient_owes: number,
      rental_flag: bool
    }) )
  }),
  aobContent: string,
  setAobIsCompleted: func,
  nextPage: func,
  setError: func,
  setNotInterested: func,
  setPayLater: func,
  blockProceed: bool
}

export default HealthInsuranceInfoBlock