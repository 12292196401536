// only edit if you have sign off -
// these colors come directly from the brand folder
// utilizing resupply color themes here

module.exports = {
  gray1: `#676c73`,
  gray2: `#ccced0`,
  gray3: `#f7f7f7`,
  darkgray: `#515151`,
  goldenrod: `#ddb879`,
  goldenrod2: `#ebcfa0`,
  goldenrod3: `#f1e3c9`,
  goldenrod4: `#faf7f2`,
  canyon: `#b16153`,
  canyon2: `#e0b3aa`,
  canyon3: `#b17368`,
  canyon4: `#efe7e5`,
  teal: `#225565`,
  teal2: `#a4bac1`,
  teal3: `#5e92a3`,
  teal4: `#143e4c`,
  plum: `#613248`,
  plum2: `#815369`,
  plum3: `#a06581`,
  plum4: `#491e32`,
  steel: `#6b7d90`,
  steel2: `#b9bfc4`,
  steel3: `#505e6c`,
  aqua: `#a7dbda`,
  aqua2: `#c4e9e8`,
  aqua3: `#f1f8f7`,
  beige: `#f0eadc`,
  error: `#e74c3c`
}
