/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'
import AddressItemType from '../types/AddressItemType'

function useDisableAddressSave( addressData: AddressItemType, latestSave: AddressItemType ) {

  const [ disableSave, setDisableSave ] = useState<boolean>( true )

  useEffect( () => {
    if ( addressData ) {
      const keyDiffers = Object.keys( addressData ).find( ( addressItem: string ) => {
        return ( addressData as any )[ addressItem ] !== ( latestSave as any )[addressItem]
      })
      setDisableSave( !keyDiffers )
    }
  }, [ addressData, latestSave ] )

  return disableSave
}

export default useDisableAddressSave