import * as React from 'react'
import { afDiabetesLogo } from 'assets/images'

const Logo = () : JSX.Element => {

  return (
    <img
      className="object-contain my-6"
      src={afDiabetesLogo}
      alt="Aeroflow Diabetes Logo"
      height={100}
      width={500}
      loading="lazy"
    /> )

}

export default Logo
