import React, { useState, useRef } from 'react'
import { Validators } from 'components/inputs/components'
import { AF_SUPPORT_PHONE_NUMBER } from 'constants/phoneNumbers'
import AddressInfoEdit from './AddressInfoEdit'
import LoadingSpinner from 'components/LoadingSpinner'
import AddressItemType from '../types/AddressItemType'
import BackButton from '../../backButton'
import AnimatedHeightDiv from 'components/animatedHeightDiv/AnimatedHeightDiv'
import { updateAddress } from 'modules/aobPayments/fetchPromises'
import postDemographics from 'utils/demographics'
import { reportToSentry } from 'utils/reportToSentry'
import useDisableAddressSave from '../hooks/useDisableAddressSave'
import { capitalize } from 'lodash'
import { useAddressStore } from './AddressStore'

function AddressInfoBlock() : JSX.Element {

  const [ loading, setLoading ] = useState<boolean>( false )
  const [ error, setError ] = useState<string | null>( null )
  const [ addressData, setAddressData ] = useAddressStore( state => [ state.address, state.setAddress ] )
  const [ view, setView ] = useState<string>( addressData.street?.length ? `read` : `edit` )
  const [ latestSave, setLatestSave ] = useState<AddressItemType>( addressData )

  const refs = {
    firstNameRef: useRef<HTMLInputElement>( null ),
    lastNameRef: useRef<HTMLInputElement>( null ),
    streetRef: useRef<HTMLInputElement>( null ),
    cityRef: useRef<HTMLInputElement>( null ),
    stateRef: useRef<HTMLInputElement>( null ),
    zipRef: useRef<HTMLInputElement>( null )
  }

  const searchParams = new URLSearchParams( window.location.search )
  const hash = searchParams.get( `sgh` ) ?? searchParams.get( `txt` )

  const disableSave = useDisableAddressSave( addressData, latestSave )

  function handleAddressUpsertCallback(){
    setLatestSave( Object.assign({}, latestSave, {
      state: addressData.state,
      city: addressData.city,
      zip: addressData.zip,
      street: addressData.street
    }) )
  }

  function handleAddressUpsertFailure( errorMessage?: string, errorObj?: Error ) {
    setError( `Update shipping address info unsuccessful. Please try again or reach out to support at ${AF_SUPPORT_PHONE_NUMBER}.` )
    handleResetView()
    reportToSentry( new Error ( `update customer address mutation error -> AddressInfoBlock`, {
      cause: errorObj
    }), {
      hash,
      errorMessage
    })
  }

  const updateName = async () => {
    setLoading( true )
    const demographicsResponse = await postDemographics( `PatientHash ${hash}`, {
      firstName: addressData.first_name,
      lastName: addressData.last_name
    })
      .catch( ( error: Error ) => {
        reportToSentry( new Error( `AOB: There was an exception when trying to update a patients demographics on payments page` ), {
          hash,
          error
        })
        setView( `read` )

        return setError( `Update shipping address info unsuccessful. Please try again or reach out to support at ${AF_SUPPORT_PHONE_NUMBER}.` )
      })
      .finally( () => setLoading( false ) )

    if ( !demographicsResponse ) {
      handleResetView()

      return setError( `Update shipping address info unsuccessful. Please try again or reach out to support at ${AF_SUPPORT_PHONE_NUMBER}.` )
    }

    setLatestSave( Object.assign({}, latestSave, {
      first_name: addressData.first_name,
      last_name: addressData.last_name
    }) )
  }

  const handleAddressUpdate = async () => {
    setLoading( true )
    const updateAddressResponse = await updateAddress({
      address: addressData.street,
      state: addressData.state,
      city: addressData.city,
      zip: addressData.zip
    })
      .catch( ( error: Error ) => {
        return handleAddressUpsertFailure( ``, error )
      })

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const updateAddressAccess = await ( updateAddressResponse as any ).json()
      .catch( ( error: Error ) => {
        return handleAddressUpsertFailure( ``, error )
      })
      .finally( () => setLoading( false ) )

    if ( updateAddressAccess?.errors?.length ) return handleAddressUpsertFailure( updateAddressAccess.errors[0] )

    return handleAddressUpsertCallback()
  }

  const handleSaveAddressInfo = async () => {
    if ( !Validators.name( addressData.first_name ) && refs?.firstNameRef?.current ) return refs.firstNameRef.current.focus()
    if ( !Validators.name( addressData.last_name ) && refs?.lastNameRef?.current ) return refs.lastNameRef.current.focus()
    if ( !Validators.street( addressData.street ) && refs?.streetRef?.current ) return refs.streetRef.current.focus()
    if ( !Validators.city( addressData.city ) && refs?.cityRef?.current ) return refs.cityRef.current.focus()
    if ( !Validators.zipCode( addressData.zip ) && refs?.zipRef?.current ) return refs.zipRef.current.focus()

    setError( null )

    // only post demographics if name is changed and only change address if an address field is changed
    if ( addressData.first_name !== latestSave.first_name || addressData.last_name !== latestSave.last_name ) await updateName()
    if ( addressData.city !== latestSave.city || addressData.zip !== latestSave.zip || addressData.street !== latestSave.street || addressData.state !== latestSave.state ) await handleAddressUpdate()

    setView( `read` )
  }

  const handleChange = ( e: React.ChangeEvent<HTMLInputElement> ) => {
    setAddressData( Object.assign({}, addressData, {
      [e.target.name]: e.target.value
    }) )
  }

  /* This function resets back to the read screen with the latest saved data */
  const handleResetView = () => {
    setAddressData( latestSave )
    setView( `read` )
  }

  return (
    <div className="p-5 bg-gray3 rounded-xl text-lg">
      <AnimatedHeightDiv
        display
        heightDependencies={[ view, loading ]}
      >
        <>
          {
            view === `edit` ?
              <div className="flex flex-col items-center relative">
                <BackButton tailwindPositioning="top-1 left-1" handleClick={handleResetView} />
                <p className="font-base mb-1 text-lg">{`Shipping Address:`}</p>
                <div className="w-3/4 mb-6">
                  {
                    loading ?
                      <div className="my-5">
                        <LoadingSpinner />
                      </div>
                      :
                      <AddressInfoEdit
                        addressData={addressData}
                        refs={refs}
                        handleChange={handleChange}
                      />
                  }
                </div>
                <button className="btn-secondary max-w-xs" disabled={loading || disableSave} onClick={handleSaveAddressInfo}>{`Save Address`}</button>
              </div>
              :
              <div className="flex flex-col items-center">
                <p className="font-base mb-1 text-lg">{`Shipping Address:`}</p>
                <p className="font-light text-lg truncate">{`${capitalize( addressData?.first_name ) ?? ``} ${capitalize( addressData?.last_name )}` ?? ``}</p>
                <p className="font-light text-lg truncate">{addressData?.street ?? ``}</p>
                <p className="font-light text-lg truncate">{`${addressData?.city ?? ``}, ${addressData?.state ?? ``} ${addressData?.zip ?? ``}`}</p>
                {
                  error &&
                  <p className="text-error my-5">{error}</p>
                }
                <p className="underline decoration-teal text-teal text-lg max-w-fit my-2 mx-auto">
                  <span className="cursor-pointer" onClick={() => { return setView( `edit` ) }}>{`Edit Shipping Address`}</span>
                </p>
              </div>
          }
        </>
      </AnimatedHeightDiv>
    </div>
  )
}

export default AddressInfoBlock