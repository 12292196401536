import { createJSONStorage, persist } from 'zustand/middleware'
import { create } from 'zustand'
import AddressItemType from '../types/AddressItemType'

type AddressStore = {
  address: AddressItemType,
  setAddress( _newAddress : AddressItemType ) : void
}

export const useAddressStore = create<AddressStore>()(
  persist( ( set ) => {
    return {
      address: {
        first_name: ``,
        last_name: ``,
        street: ``,
        city: ``,
        state: ``,
        zip: ``
      },
      setAddress: ( _newAddress : AddressItemType ) => {
        set({
          address: _newAddress
        })
      }
    }
  },
  {
    name: `address-store`,
    storage: createJSONStorage( () => sessionStorage )
  })
)