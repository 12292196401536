import PropTypes from 'prop-types'
import Item from './Item'
import AnimatedHeightDiv from 'components/animatedHeightDiv/AnimatedHeightDiv'

function OrderItemsDetails({
  invoice,
  displayOrderItems
}) {

  return (
    <AnimatedHeightDiv
      display={displayOrderItems}
      heightDependencies={[ displayOrderItems ]}
    >
      <div className="flex flex-col justify-center items-center mt-2 pb-10 mb-8 min-w-fit">
        <p className="md:text-lg text-center">{`Supply items available now`}</p>
        <div className="flex flex-col w-10/12 md:w-8/12 justify-center items-left my-6">
          {
            invoice.details?.length > 0 &&
            invoice.details.map( ( detail ) => {
              return (
                <Item
                  key={detail.dme_id}
                  detail={detail}
                />
              )
            })
          }
        </div>
      </div>
    </AnimatedHeightDiv>
  )
}

OrderItemsDetails.propTypes = {
  invoice: PropTypes.object,
  displayOrderItems: PropTypes.bool,
  patientId: PropTypes.string,
  setInvoice: PropTypes.func,
  setEditOrderError: PropTypes.func,
  setRemovedItems: PropTypes.func,
  removedItems: PropTypes.array,
  blockProceed: PropTypes.bool,
  setBlockProceed: PropTypes.func
}


export default OrderItemsDetails