import React, { useEffect, useState, useRef } from 'react'
import { icoXAob } from 'assets/images'
import { useSpring, animated as a } from 'react-spring'
import PropTypes from 'prop-types'

function Tooltip({tooltipContent, children, positioning, center, mobileWidth, inline = false, hide = false}) {

  // used for animation
  const [ tooltipOpacityToggle, setTooltipOpacityToggle ] = useState( false )
  const [ displayTooltip, setDisplayTooltip ] = useState( false )

  const tooltipRef = useRef( null )
  const iconRef = useRef( null )

  const tooltipStyle = useSpring({
    to: {
      opacity: tooltipOpacityToggle ? 1 : 0
    },
    onRest: () => {
      if ( !tooltipOpacityToggle ) {
        setDisplayTooltip( false )
      }
    },
    config: {
      duration: 400
    }
  })

  useEffect( () => {
    document.removeEventListener( `click`, triggerHideAnimation )
    if ( displayTooltip ) document.addEventListener( `click`, triggerHideAnimation )

    return () => {
      document.removeEventListener( `click`, triggerHideAnimation )
    }
  }, [ displayTooltip ] )

  const triggerDisplayAnimation = () => {
    if ( hide ) return
    setDisplayTooltip( true )
    setTooltipOpacityToggle( true )
  }

  const triggerHideAnimation = ( e ) => {
    if ( !tooltipRef?.current?.contains( e.target ) && !iconRef.current?.contains( e.target ) ) setTooltipOpacityToggle( false )
  }

  // this logic is to give control over the positioning and width of the tooltip
  const tooltipStyling = `absolute m-auto p-4 pb-5 bg-white border border-solid border-gray2 shadow-lg w-${mobileWidth ?? `11/12`} ${!displayTooltip ? `pointer-events-none` : ``} md:w-96 rounded-lg z-50 ${ positioning } ${center && `top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2`}`

  return (
    <div
      onClick={() => { if ( !displayTooltip ) triggerDisplayAnimation() }}
      className={inline ? `inline-block` : ``}
    >
      <a.div
        style={tooltipStyle}
        className={tooltipStyling}
        ref={tooltipRef}
      >
        <img
          src={icoXAob}
          alt="ico-x"
          className="absolute right-0 top-2 px-3 hover:opacity-80 cursor-pointer object-contain"
          width={40}
          onClick={() => { return setTooltipOpacityToggle( false ) }}
        />
        {tooltipContent}
      </a.div>
      <div
        className={`min-w-fit`}
        ref={iconRef}
      >
        {
          React.cloneElement( children ?? <></> )
        }
      </div>
    </div>
  )
}

Tooltip.propTypes = {
  children: PropTypes.oneOfType( [
    PropTypes.arrayOf( PropTypes.node ),
    PropTypes.node
  ] ),
  tooltipContent: PropTypes.oneOfType( [
    PropTypes.arrayOf( PropTypes.node ),
    PropTypes.node
  ] ),
  positioning: PropTypes.any,
  center: PropTypes.bool,
  mobileWidth: PropTypes.string,
  inline: PropTypes.bool,
  hide: PropTypes.bool
}

export default Tooltip