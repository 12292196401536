import PropTypes from 'prop-types'
import Logo from './logo/Logo'
import { useNavigate } from 'react-router-dom'
import getHash from 'utils/getHash'

function AobError({message}) {
  const navigate = useNavigate()

  return (
    <div className="w-full mx-auto text-center mt-36 bg-white px-5">
      <div className="absolute z-50 top-3 left-0 right-0 w-full max-w-md px-3 mx-auto">
        <Logo />
      </div>
      <div className="max-w-xl m-auto">
        <p className="text-canyon">{message}</p>
      </div>
      <div className="mt-5 lg:mt-10 min-w-fit w-1/4 m-auto">
        {
          // want to hide refresh button if use is coming from resupply since refreshing will only clear claimed items and send user back to homepage when aob mounts again
          ( getHash() ) &&
          <button className="btn-primary-solid" onClick={() => { window.location.reload() }}>{`Refresh`}</button>
        }
        <button className="btn-primary" onClick={() => { navigate( `/logout` ) }}>{`Logout`}</button>
      </div>
    </div>
  )
}

AobError.propTypes = {
  message: PropTypes.string
}

export default AobError