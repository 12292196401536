/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
// TODO: add select functionality and UX
import { ViewPayMethodPropsNoSave } from "../props"
import { BankPaymentMethod, CardPaymentMethod } from "../types"
import { icoCheckAob, icoX } from 'assets/images'
import LoadingSpinner from "components/LoadingSpinner"

export default function ViewPayMethod({payMethod, handleDelete, toggleSelect, isSelected, displayError, displayLoading} : ViewPayMethodPropsNoSave ) {

  const { paymentMethodType } = payMethod

  if ( displayLoading ) return <div className="my-2"><LoadingSpinner /></div>

  return (
    <>
      { displayError && <p className="my-2 text-error">{displayError}</p> }
      <div className="mx-auto flex w-96 flex-col items-center justify-start my-8 p-4">
        <div className="flex gap-3 items-center">
          <div className="flex items-center">
            <div
              className="mx-1 flex flex-row gap-3 align-middle items-center justify-center"
            >
              <button
                className="hover:opacity-80"
                onClick={toggleSelect}
              >
                {
                  isSelected ? (
                    <img
                      src={icoCheckAob}
                      alt="check mark"
                      width={24}
                      height={24}
                    />
                  ) : (
                    <div className="w-6 h-6 rounded-full border border-gray-300" />
                  )
                }
              </button>
              { paymentMethodType === `ach` ? ( // we show bank
                <p
                  className="cursor-pointer text-lg md:text-xl text-left font-light max-w-2xl whitespace-nowrap"
                  onClick={toggleSelect}
                >
                  {`Account ending in ${( payMethod as BankPaymentMethod ).accountNumber?.slice( -4 )}`}
                </p>
              ) : ( // else we show card
                <p
                  className="cursor-pointer text-lg md:text-xl text-center font-light max-w-2xl whitespace-nowrap mx-1"
                  onClick={toggleSelect}
                >
                  {`Card ending in ${( payMethod as CardPaymentMethod ).cardNumber?.slice( -4 )}`}
                </p>
              )}
              <button
                className="hover:opacity-80"
                onClick={handleDelete}
              >
                <img
                  src={icoX}
                  width={20}
                  height={20}
                  alt="cancel icon"
                />
              </button>

            </div>
          </div>

        </div>
      </div>
    </>
  )
}