import { SelectInputProps } from '../types.d'

const SelectInput = ({
  name,
  value,
  onChange,
  required = false,
  label = ``,
  className = `input`,
  errorClassName = `select-error`,
  labelClassName = `input-label`,
  errorMessageClassName = `text-canyon text-xs px-3`,
  asteriskClassName = `font-semibold text-canyon pl-2`,
  children,
  errorMessage = ``,
  id = undefined,
  reference,
  disabled = false,
  hidden = false,
  ...rest
} : SelectInputProps ) : JSX.Element => {

  // sets error, disabled, or normal className on input
  const inputClassName = ( errorMessage ) ? errorClassName : (
    ( disabled ) ? `input-disabled ${className}` : className
  )

  return (
    <div className={`flex flex-col`}>
      {label && !hidden && (
        <label htmlFor={id || name} className={labelClassName}>
          {label}
          {required && <span className={asteriskClassName}>{`*`}</span>}
        </label>
      )}

      <select
        id={id || name}
        name={name}
        value={value}
        onChange={onChange}
        className={inputClassName}
        required={required}
        ref={reference}
        hidden={hidden}
        {...rest}
      >
        {children}
      </select>

      {errorMessage && <p className={errorMessageClassName}>{errorMessage}</p>}
    </div>
  )
}

export default SelectInput
