import React, { useState } from 'react'
import { formatMoney, splitMoney } from 'utils/money'
import BackButton from '../backButton'
import { PaymentMethodsBlock } from 'components/payments'

type UpfrontPaymentBlockProps = {
  splitSinglePayment: boolean | null;
  setSplitSinglePayment: React.Dispatch<React.SetStateAction<boolean | null>>;
  invoice: {
    total_patient_owes: number;
  };
  selected?: string | null;
}

function UpfrontPaymentBlock({splitSinglePayment, setSplitSinglePayment, invoice }: UpfrontPaymentBlockProps ) : JSX.Element {

  const [ displayPaymentMethods, setDisplayPaymentMethods ] = useState( false )

  const handleSplitPaymentsSelection = ( split: boolean ) => {
    setSplitSinglePayment( split )
    setDisplayPaymentMethods( true )
  }

  const upfront = invoice.total_patient_owes?.toFixed( 2 )

  if ( !displayPaymentMethods ) {
    return (
      <div className="max-w-2xl mx-auto">
        <div className="flex flex-col items-center w-full max-w-md mx-auto my-5 gap-5">
          <p className="text-lg md:text-xl font-bold text-center">{`Upfront Payment Method`}</p>
          <p className="md:text-lg font-bold text-center">{`Choose One`}</p>
          <button
            className="payment-btn mb-10"
            onClick={() => { handleSplitPaymentsSelection( false ) }}
          >
            <p className="pt-2 md:text-lg">{`One time payment of`}</p>
            <p className="pb-2 md:text-lg">{`$${upfront}`}</p>
          </button>
          <p>{`OR`}</p>
          <button
            className="payment-btn mt-7"
            onClick={() => { handleSplitPaymentsSelection( true ) }}
          >
            <p className="pt-2 md:text-lg">{`3 monthly payments of`}</p>
            <p className="pb-2 md:text-lg">{`${formatMoney( splitMoney( invoice.total_patient_owes, 3 ) )}`}</p>
          </button>
          <p className="-mt-3">{`($${upfront} over 3 months)`}</p>
        </div>
      </div>
    )
  }

  return (
    <div className="flex flex-col items-center w-full mx-auto relative my-5">
      <div className="max-w-md">
        <div className="relative">
          <p className="text-lg md:text-xl font-bold text-center mb-4 px-10 md:px-5">{`Payment Method for ${splitSinglePayment ? `Split` : `Full` } Upfront Payment`}</p>
          <BackButton
            handleClick={() => {
              setDisplayPaymentMethods( false )
              setSplitSinglePayment( null )
            }}
            tailwindPositioning="top-0 -left-3 md:-left-6"
          />
        </div>

        <PaymentMethodsBlock blockId="upfront" />

      </div>
    </div>
  )
}

export default UpfrontPaymentBlock