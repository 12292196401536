import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import routes from './routes'

const App = () : JSX.Element => {
  return (
    <Router>
      <Routes>
        {
          routes.map( route => {
            return (
              <Route
                key={route.path}
                {...route}
              />
            )
          })
        }
      </Routes>
    </Router>
  )
}

export default App
