import { usePayMethodsStore } from 'state'
import PayMethod from './PayMethod'
import { type PaymentProfileResponse } from './types'
import AddNewPayMethod from './View/AddNew'
import { useFetch } from 'hooks'
import { serializePaymentProfileResponse } from './utils'
import { useState } from 'react'
import LoadingSpinner from 'components/LoadingSpinner'

export default function PayMethodsBlock({blockId} : {blockId: string}) {

  const [ payMethods, setPayMethods ] = usePayMethodsStore( ( state ) => [ state.payMethods, state.setPayMethods ] )
  const { toggleSelectedPayMethod } = usePayMethodsStore()
  const [ shouldSkipFetch, setShouldSkipFetch ] = usePayMethodsStore( ( state ) => [ state.shouldSkipFetch, state.setShouldSkipFetch ] )
  const shouldAddNewBeHidden = payMethods?.find( ( payMethod ) => payMethod.isEditing === true )
  const [ shouldAddNewBeExpanded, setShouldAddNewBeExpanded ] = useState( payMethods?.length === 0 )

  const { isLoading } = useFetch<PaymentProfileResponse>( `${process.env.REACT_APP_AUTHNET_PAYMENTS_V2_URL}/profile`, {
    method: `GET`
  }, {
    skipOnMount: shouldSkipFetch,
    onErrorOverride: ( _error : unknown ) => {
      // We don't want to do anything on error since this is fetching existing payment profiles
    },
    onSuccess: ( data: PaymentProfileResponse ) => {
      setShouldSkipFetch( true ) // This allows for us to not fetch the payment methods again if they have already been fetched from another block
      if ( data?.messages?.resultCode?.toLowerCase() === `ok` ) {
        const _payMethods = serializePaymentProfileResponse( data )
        setPayMethods( _payMethods )
        const lastSavedPayMethod = _payMethods?.length > 0 ? _payMethods[ _payMethods.length - 1 ]?.paymentMethodId : null
        if ( lastSavedPayMethod !== null ) {
          toggleSelectedPayMethod( blockId, lastSavedPayMethod ) // Automatically select the last saved payMethod
          setShouldAddNewBeExpanded( false ) // Have the add new payment not be expanded when a payment is already loaded
        }
        setShouldSkipFetch( true )
      }
    }
  })

  if ( isLoading ) return (
    <div className="max-w-lg mx-auto my-10">
      <p className="my-2">{`Loading payment profiles...`}</p>
      <LoadingSpinner />
    </div> )

  return (
    <div key={blockId}>
      <div className="flex flex-col gap-2">

        { payMethods.map( ( payMethod ) => {
          const { paymentMethodId } = payMethod

          return (
            <PayMethod
              key={paymentMethodId}
              payMethodId={paymentMethodId}
              blockId={blockId}
            />
          )
        })
        }
      </div>
      {!shouldAddNewBeHidden && <AddNewPayMethod expanded={shouldAddNewBeExpanded} setExpanded={setShouldAddNewBeExpanded} />}
    </div>
  )
}