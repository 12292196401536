import { PAYMENT_PORTAL_PATH } from 'modules/aobPayments/constants'
import { Navigate } from 'react-router-dom'
import { NotFound } from 'modules/notFound'
import AOBPaymentsPortal from 'modules/aobPayments'
import { UnAuthorized } from 'modules/unAuthorized'

export const UNAUTHORIZED_PAGE_PATH = `/unauthorized`

const routes = [
  {
    path: `/`,
    element: <Navigate to={PAYMENT_PORTAL_PATH} />
  },
  {
    path: PAYMENT_PORTAL_PATH,
    element: <AOBPaymentsPortal />
  },
  {
    path: UNAUTHORIZED_PAGE_PATH,
    element: <UnAuthorized />
  },
  {
    path: `*`,
    element: <NotFound />
  }
]

export default routes