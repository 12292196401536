import { useState, useRef } from "react"
import { SelectInput, TextInput } from "components/inputs/components"
import { useValidate } from "hooks"
import { EditBankMethodProps } from "../props"
import LoadingSpinner from "components/LoadingSpinner"

export default function EditBankMethod({
  payMethod, setPayMethod, onSave, onCancel, displayLoading, displayError
} : EditBankMethodProps ) {

  const [ confirmAccountNumber, setConfirmAccountNumber ] = useState( `` )

  const inputRefs = {
    accountNumber: useRef<HTMLInputElement>( null ),
    confirmAccountNumber: useRef<HTMLInputElement>( null ),
    routingNumber: useRef<HTMLInputElement>( null ),
    nameOnAccount: useRef<HTMLInputElement>( null ),
    accountType: useRef<HTMLSelectElement>( null )
  }

  const { evaluateErrorsOnChange,
    evaluateErrorsOnSubmit,
    evaluateErrorsOnBlur,
    formErrors : errors,
    errorsPresent,
    customInputHandlers,
    shouldShowErrors } = useValidate( inputRefs )

  const { disableDefaultAction } = customInputHandlers

  function handleInputChange( event : React.ChangeEvent<HTMLInputElement> ) {
    evaluateErrorsOnChange( event )
    const { name, value } = event.currentTarget
    if ( name === `confirmAccountNumber` ) return setConfirmAccountNumber( value )

    setPayMethod({
      ...payMethod,
      [name]: value
    })
  }

  function handleFormSubmit( event : React.FormEvent ) {
    event.preventDefault()
    const hasErrors = evaluateErrorsOnSubmit()
    if ( !hasErrors && onSave ) onSave()
  }

  const inputHandlers = {
    onChange: handleInputChange,
    onBlur: evaluateErrorsOnBlur
  }

  const disableCopyPasteCutHandlers = {
    onPaste: disableDefaultAction,
    onCopy: disableDefaultAction,
    onCut: disableDefaultAction
  }

  return (
    <form onSubmit={handleFormSubmit}>
      <p className="md:text-lg font-bold text-center my-4">{`Add Bank Account`}</p>
      <TextInput
        id="routingNumber"
        name="routingNumber"
        label="Routing number"
        type="number"
        value={payMethod.routingNumber}
        errorMessage={shouldShowErrors ? errors.routingNumber : ``}
        required
        reference={inputRefs.routingNumber}
        {...inputHandlers}
      />

      <TextInput
        id="accountNumber"
        name="accountNumber"
        label="Account number"
        type="number"
        value={payMethod.accountNumber}
        errorMessage={shouldShowErrors ? errors.accountNumber : ``}
        required
        reference={inputRefs.accountNumber}
        {...inputHandlers}
      />

      <TextInput
        id="confirmAccountNumber"
        name="confirmAccountNumber"
        label="Confirm Account number"
        type="number"
        value={confirmAccountNumber}
        errorMessage={shouldShowErrors ? errors.confirmAccountNumber : ``}
        required
        reference={inputRefs.confirmAccountNumber}
        {...inputHandlers}
        {...disableCopyPasteCutHandlers}
      />

      <SelectInput
        id="accountType"
        name="accountType"
        label="Account type"
        value={payMethod.accountType}
        errorMessage={shouldShowErrors ? errors.accountType : ``}
        required
        reference={inputRefs.accountType}
        {...inputHandlers}
      >
        <option value="checking">{`Checking`}</option>
        <option value="savings">{`Savings`}</option>
      </SelectInput>

      <TextInput
        id="nameOnAccount"
        name="nameOnAccount"
        label="Name on account"
        value={payMethod.nameOnAccount}
        errorMessage={shouldShowErrors ? errors.nameOnAccount : ``}
        required
        reference={inputRefs.nameOnAccount}
        {...inputHandlers}
      />

      <hr className="my-2"></hr>

      { displayLoading && <div className="my-2"><LoadingSpinner /></div> }

      { displayError && <p className="my-2 text-error">{displayError}</p> }

      <div className="flex flex-wrap mx-auto gap-2 max-w-xs">
        <button
          className="btn btn-secondary"
          disabled={errorsPresent || displayLoading}
          type="submit"
        >
          {`Use Payment Method`}
        </button>
        <button
          className="btn btn-primary"
          disabled={displayLoading}
          onClick={onCancel}
        >
          {`Cancel`}
        </button>
      </div>
    </form>
  )
}