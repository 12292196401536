import { CheckboxInput } from 'components/inputs/components'
import { PaymentMethodsBlock } from 'components/payments'
import { getNextMonthDate } from 'utils/time'
import { formatMoney } from 'utils/money'
import { icoInfoAob } from 'assets/images'
import RentalTooltip from './RentalTooltip'
import Tooltip from 'components/tooltip'

type RecurringPaymentBlockProps = {
  invoice: {
    est_monthly_due: number;
    number_of_months: number;
  };
  useSamePaymentCheck: boolean;
  setUseSamePaymentCheck: ( _useSamePaymentCheck: boolean ) => void;
}

function RecurringPaymentBlock({ invoice, useSamePaymentCheck, setUseSamePaymentCheck } : RecurringPaymentBlockProps ) {

  if ( !invoice?.est_monthly_due ) return null

  const startDate = getNextMonthDate()


  return (
    <div className="relative">
      <Tooltip
        tooltipContent={<RentalTooltip />}
        positioning={{
          bottom: 0
        }}
      >
        <div className="flex justify-center items-center pt-16">
          <p className="text-lg md:text-xl font-bold text-center">{`Your Rental Supplies Payment Method`}</p>
          <img
            src={icoInfoAob}
            alt="ico-info"
            className="px-2 hover:opacity-80 cursor-pointer object-contain"
            width={37}
          />
        </div>
      </Tooltip>
      <p className="md:text-lg text-center font-light max-w-2xl my-4">{`Your first payment will be $${invoice.est_monthly_due} and you will be charged for ${invoice.number_of_months} months starting on ${`${startDate.getMonth() + 1}/${startDate.getDate()}/${startDate.getFullYear()}`}.`}</p>
      <p className="md:text-lg text-center font-light max-w-2xl">{`${invoice.number_of_months} month total: `}{formatMoney( invoice.est_monthly_due * invoice.number_of_months )}</p>
      <div className="max-w-lg mx-auto mt-10 mb-4 themed-checkboxes">
        <CheckboxInput
          name="additionalPayments"
          label="Use the same payment method as above"
          className="mr-2 focus:outline-none rounded-full cursor-pointer"
          labelClassName="cursor-pointer text-lg"
          onChange={() => setUseSamePaymentCheck( !useSamePaymentCheck )}
          value={useSamePaymentCheck} id={`useSamePaymentCheck`}
        />
      </div>
      {!useSamePaymentCheck && <PaymentMethodsBlock blockId="recurring" />}
    </div>
  )
}

export default RecurringPaymentBlock