import React from 'react'
import ReactHtmlParser from 'react-html-parser'
import PropTypes from 'prop-types'


function TermsAndConditionsTooltip({aobContent}) {


  const transform = ( node ) => {
    if ( node?.name === `style` ) return null
    if ( node?.name === `p` ) {
      node.attribs.class = `my-5`
    }
    if ( node?.name === `span` ) {
      if ( node?.attribs?.class?.includes( `s_6555B096` ) ) node.attribs.class = `font-bold text-lg`
      if ( node?.attribs?.class?.includes( `s_4A6E6CA9` ) ) node.attribs.class = `font-bold text-xl uppercase`
    }
  }

  return (
    <div className="max-h-96 overflow-y-scroll w-11/12">
      <div className={`px-2 text-left`}>
        {
          ReactHtmlParser( aobContent, {
            transform
          })
        }
      </div>
    </div>
  )
}

TermsAndConditionsTooltip.propTypes = {
  aobContent: PropTypes.string
}

export default TermsAndConditionsTooltip