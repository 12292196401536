import React, { useState } from 'react'
import PricingEstimateBlockWrapper from '../PricingEstimateBlockWrapper'
import PropTypes from 'prop-types'
import OrderItemsDetails from './OrderItemsDetails'
import { icoMinus, icoPlus } from 'assets/images'

function OrderSummaryDetails({ invoice }) {

  const [ displayOrderItems, setDisplayOrderItems ] = useState( false )

  return (
    <PricingEstimateBlockWrapper>
      <div className="flex">
        <p className="text-xl tracking-wide font-bold text-center py-4 cursor-pointer" onClick={() => { return setDisplayOrderItems( !displayOrderItems ) }}>{`Order Summary Details`}</p>
        <img
          src={displayOrderItems ? icoMinus : icoPlus}
          alt={displayOrderItems ? `ico-minus` : `ico-plus`}
          className="object-contain ml-2 cursor-pointer hover:opacity-80"
          width={18}
          onClick={() => { return setDisplayOrderItems( !displayOrderItems ) }}
        />
      </div>
      <OrderItemsDetails
        invoice={invoice}
        displayOrderItems={displayOrderItems}
      />
    </PricingEstimateBlockWrapper>
  )
}

OrderSummaryDetails.propTypes = {
  invoice: PropTypes.object,
  patientId: PropTypes.string,
  setInvoice: PropTypes.func,
  removedItems: PropTypes.array,
  setRemovedItems: PropTypes.func,
  blockProceed: PropTypes.bool,
  setBlockProceed: PropTypes.func
}

export default OrderSummaryDetails