import * as React from 'react'
import PropTypes from 'prop-types'
import { AobLoader, AobError } from '../components'
import { PaymentSummary, OrderSummaryDetails, HealthInsuranceInfoBlock } from '../components/pricingEstimate'
import { PayButtonBlock, PayLaterModal } from '../components/pricingEstimate/healthInsuranceInfoBlock/components'
import LoadingSpinner from 'components/LoadingSpinner'
import getHash from 'utils/getHash'
import { submitAobAcceptance } from '../fetchPromises'
import { reportToSentry } from 'utils/reportToSentry'
import { defaultErrorMessage } from '../constants'
import AddressInfoBlock from '../components/paymentCustomerDetailsBlock/addressInfoBlock'
import { CheckboxInput } from 'components/inputs/components'
import PricingEstimateBlockWrapper from '../components/pricingEstimate/PricingEstimateBlockWrapper'
import Tooltip from 'components/tooltip'
import { TermsAndConditionsTooltip } from '../components/pricingEstimate/tooltips'

function OrderSummary({
  setAobIsCompleted,
  setPayLater,
  invoice,
  aobContent,
  setError,
  setNotInterested,
  ...pageProps
}) {


  const [ openModal, setOpenModal ] = React.useState( false )
  const [ submittingAob, setSubmittingAob ] = React.useState( false )
  const [ verifiedAddress, setVerifiedAddress ] = React.useState( false )

  const hash = getHash()

  const aobAcceptanceSubmission = async ( payLaterSelected ) => {
    try {
      const acceptAobResponseData = await submitAobAcceptance()
      if ( !acceptAobResponseData?.meta?.status || acceptAobResponseData.meta.status !== `Accepted` ) {
        setSubmittingAob( false )

        reportToSentry( new Error( `CGM AOB: accept aob submission failed` ), {
          response: JSON.stringify( acceptAobResponseData ),
          hash
        })

        return setError( defaultErrorMessage )
      }

      setPayLater( payLaterSelected )
      setAobIsCompleted( true )
      setSubmittingAob( false )

      return pageProps.nextPage()
    } catch ( error ) {
      reportToSentry( new Error( `CGM AOB: accept aob submission failed`, {
        cause: error
      }), {
        hash
      })
      setError( defaultErrorMessage )

      return setSubmittingAob( false )
    }
  }

  const handleSubmitAob = async ( payLaterSelected = false ) => {
    setError( null )
    setSubmittingAob( true )
    await aobAcceptanceSubmission( payLaterSelected )
  }

  if ( invoice !== null && Object.keys( invoice ).length === 0 ) return <AobError message={`Oops... We are having trouble getting your price estimate. Please try again or reach out to customer service.`} />

  if ( invoice === null || aobContent === undefined ) return <AobLoader message={`Please hold tight while we get your estimate...`} />

  return (
    <div className="relative max-w-full mx-auto w-full md:w-10/12 lg:w-1/2 flex flex-col gap-5 bg-gray3">
      <PricingEstimateBlockWrapper>
        <PaymentSummary invoice={invoice} />
        <div className="w-full mx-auto">
          <AddressInfoBlock />
          <div
            className="flex cursor-pointer mt-5 mb-8 justify-center themed-checkboxes"
            onClick={() => { return setVerifiedAddress( !verifiedAddress ) }}
          >
            <CheckboxInput
              name="current_shipping"
              className="cursor-pointer mr-3"
              value={verifiedAddress}
              onChange={() => { return }}
              id="addressInput"
              label={`I verify that this is my current shipping address`}
              labelClassName="text-lg font-light text-left pointer-events-none"
            />
          </div>
        </div>

        <div className="flex flex-col items-center">
          { invoice?.total_patient_owes === 0 ?
            <button
              className="btn-primary-solid max-w-xs"
              onClick={() => { return handleSubmitAob( false ) }}
              disabled={submittingAob}
            >
              {submittingAob ? <LoadingSpinner height="2.5rem" width="2.5rem" rings={3} /> : `Confirm Order`}
            </button>
            :
            <PayButtonBlock
              payLaterEligible={invoice?.pay_later_eligible_flag}
              setOpenModal={setOpenModal}
              submittingAob={submittingAob}
              submitAob={() => { return handleSubmitAob( false ) }}
              blockProceed={!verifiedAddress}
            />}
          <div className="flex flex-col items-center max-w-md mx-auto gap-2 relative">
            <Tooltip
              tooltipContent={<TermsAndConditionsTooltip aobContent={aobContent} />}
              positioning="left-0 bottom-0 right-0"
            >
              <p className="text-sm text-center font-light mb-10 mt-2 max-w-md">
                <span className="pointer-events-none">
                  {`By continuing, I authorize Aeroflow Healthcare to contact me by email, phone, or SMS. Aeroflow will not share or distribute this information. I also agree to `}
                </span>
                <span className="text-teal3 text-sm font-normal underline cursor-pointer">
                  {`Aeroflow Terms and Conditions.`}
                </span>
              </p>
            </Tooltip>
          </div>
        </div>
      </PricingEstimateBlockWrapper>

      {
        invoice?.details?.length > 0 &&
        <OrderSummaryDetails invoice={invoice} />
      }
      <HealthInsuranceInfoBlock
        invoice={invoice}
        nextPage={pageProps.nextPage}
        aobContent={aobContent}
        setAobIsCompleted={setAobIsCompleted}
        setError={setError}
        setPayLater={setPayLater}
        setNotInterested={setNotInterested}
      />
      {
        invoice?.pay_later_eligible_flag && invoice?.total_patient_owes !== 0 &&
        <PayLaterModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          submittingAob={submittingAob}
          handleConfirm={async () => { return await handleSubmitAob( true ) }}
        />
      }
    </div>
  )
}

OrderSummary.propTypes = {
  setAobIsCompleted: PropTypes.func,
  invoice: PropTypes.object,
  aobContent: PropTypes.string,
  setError: PropTypes.func,
  setPayLater: PropTypes.func,
  customerData: PropTypes.object,
  setInvoice: PropTypes.func,
  setRemovedItems: PropTypes.func,
  removedItems: PropTypes.array,
  setNotInterested: PropTypes.func
}

export default OrderSummary